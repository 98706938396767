import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

import {
  SectionContainer,
  SectionInnerWrapper,
} from "../../../components/Layout/styled"

import Footer from "../../../components/Layout/Footer"
import SeoComp from "../../../components/SeoComp"

const PaymentMethods = ({ intl, data }) =>
  data &&
  data.allDirectusUserDashboardTranslation.nodes.length > 0 && (
    <Fragment>
      <SeoComp
        description={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].description
        }
        title={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].title
        }
        twitter_card={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].twitter_card
        }
        image={
          data.allDirectusSeoTranslation.nodes[0] &&
          data.allDirectusSeoTranslation.nodes[0].image &&
          data.allDirectusSeoTranslation.nodes[0].image.data &&
          data.allDirectusSeoTranslation.nodes[0].image.data.url
        }
        hreflangPathname={{
          fr: "mon-compte/moyens-de-paiement",
          en: "dashboard/payment-methods",
          de: "dashboard/payment-methods",
          es: "dashboard/payment-methods",
        }}
      />
      <SectionContainer>
        <SectionInnerWrapper>
          <RowWrapper>
            <ColumnWrapper>
              <p>
                {
                  data.allDirectusUserDashboardTranslation.nodes[0]
                    .no_payment_method_string
                }
              </p>
            </ColumnWrapper>
          </RowWrapper>
          <RowWrapper>
            <ColumnWrapper>
              <AddPaymentButton href="/">
                {
                  data.allDirectusUserDashboardTranslation.nodes[0]
                    .add_payment_method_button
                }
              </AddPaymentButton>
            </ColumnWrapper>
          </RowWrapper>
        </SectionInnerWrapper>
      </SectionContainer>

      {data.allDirectusFooterTranslation.nodes.length > 0 && (
        <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
      )}
    </Fragment>
  )

const RowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1080px;
  width: 100%;
`

const ColumnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  color: #777;
  font-size: 13px;
  justify-content: flex-start;
  align-items: center;

  > p {
    color: #777;
    font-size: 14px;
    line-height: 1.3em;
    margin-bottom: 1.3em;
  }
`

const AddPaymentButton = styled.a`
  position: relative;
  display: inline-block;
  background-color: #262626;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  -ms-touch-action: none;
  touch-action: none;
  cursor: pointer;
  font-weight: bolder;
  text-align: center;
  color: #fff;
  text-decoration: none;
  border: 1px solid transparent;
  vertical-align: middle;
  border-radius: 0;
  margin-top: 0px;
  margin-right: 1em;
  margin-bottom: 1em;
  text-shadow: none;
  line-height: 2.4em;
  min-height: 2.5em;
  padding: 0 1.2em;
  max-width: 100%;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 13px;

  :hover {
    color: #fff;
  }
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusUserDashboardTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        no_payment_method_string
        add_payment_method_button
        language
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "moyens_de_paiment" } }
      }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(PaymentMethods)
